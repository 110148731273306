import { devtools, persist } from 'zustand/middleware'
import { create } from 'zustand'

import { createIdbStorage } from './persistent-storage/createIdbStorage.ts'

export type SerialNumber = string
export type AccessToken = string

type Page =
  | 'init'
  | 'inactivity'
  | 'session/start'
  | 'session/end'
  | 'game/start'
  | 'game/ready'
  | 'game/scores'
  | 'info/no-credits'
  | 'configuration'
  | 'sign-in'

// Example: PL-0000-0001
const serialNumberRegExp = new RegExp(/^[A-Z]{2}-\d{4}-\d{4}$/)

// Affected by game/reset event
const resettableGamePages: Page[] = ['session/start', 'game/start', 'game/ready', 'game/scores', 'session/end', 'info/no-credits']

// Note: Problem when opening in multiple windows
interface System {
  /** Terminal serial number */
  serialNumber: SerialNumber | null
  setSerialNumberFromUrl: (url: URL) => void

  /** Access token assigned to serial number */
  accessToken: AccessToken | null
  setAccessToken: (accessToken: AccessToken | null) => void

  /** Current page */
  page: Page
  setPage: (page: Page) => void

  /** Check if current page is a game page type */
  isGamePage: () => boolean
}

/**
 * @note When saving this file with hot-reload, expect in-memory state resets
 */
export const useSystemStore = create<System>()(
  devtools(
    persist(
      (set, get) => ({
        serialNumber: null,
        setSerialNumberFromUrl: ({ searchParams }) => {
          const serialNumber = searchParams.get('serial-number')

          // Skip invalid
          if (!serialNumber || !serialNumberRegExp.test(serialNumber)) {
            return
          }

          // Note: Value is reset when saving this file with hot-reload
          const state = get()

          // Reset to initial state when SN changes (ignore when missing)
          if (serialNumber !== state.serialNumber) {
            set({ serialNumber, accessToken: null }, undefined, { type: 'setSerialNumberFromUrl' })
          }
        },

        accessToken: null,
        setAccessToken: (accessToken) => set({ accessToken }, undefined, { type: 'setAccessToken' }),

        page: 'init',
        setPage: (page) => set({ page }, undefined, { type: 'setPage' }),

        isGamePage: () => {
          const { page } = get()

          return resettableGamePages.includes(page)
        },
      }),
      {
        name: 'state',
        storage: createIdbStorage('@wbc/terminal/state', 'keyval'),
        partialize: (state) => ({
          serialNumber: state.serialNumber,
          accessToken: state.accessToken,
        }),
        version: 0,
        migrate: undefined,
      }
    ),
    { name: document.title, store: 'SystemStore' }
  )
)

/**
 * Validate that JWT's sub claim matches serial number
 * Not used yet
 *
 * Standard claims:
 * {
 *   sub: string
 *   aud: 'terminal'
 *   iss: string
 *   iat: number
 * }
 */
export function validateTerminalAccessToken(serialNumber: SerialNumber, jwt: AccessToken): boolean {
  const [_headerString, payloadString = undefined] = jwt.split('.')

  let payload: { sub?: unknown } | undefined

  if (payloadString) {
    try {
      payload = JSON.parse(atob(payloadString)) as typeof payload
    } catch (syntaxError) {
      return false
    }
  }

  return payload?.sub === serialNumber
}
