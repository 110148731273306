import type { FunctionComponent } from 'preact'

import clsx from 'clsx'

import { useGameStore } from '../../store/gameStore.ts'

import playerIconUrl from './icons/player.svg'
import creditsIconUrl from './icons/credits.svg'
import topScoreIconUrl from './icons/top-score.svg'

import './GamePanels.css'

export const GamePanels: FunctionComponent = () => {
  const player = useGameStore((state) => state.player)
  const credits = useGameStore((state) => state.credits)
  const playerScoreStats = useGameStore((state) => state.playerScoresStats)

  return (
    <>
      <aside className="bx-game-panels bx-game-panels--top">
        <div className={clsx(['bx-game-panel', 'bx-game-panel--player', !player && 'bx-game-panel--hidden'])}>
          <img src={playerIconUrl} className="bx-game-panel-icon bx-game-panel-icon--large" />
          <span>{player ? player.nickName : '—'}</span>
        </div>
      </aside>

      <aside className="bx-game-panels bx-game-panels--bottom">
        <div className="bx-game-panel bx-game-panel--credits">
          <img src={creditsIconUrl} className="bx-game-panel-icon" />
          <span>{credits.toFixed(0).padStart(2, '0')}</span>
        </div>
        <div className="bx-game-panel bx-game-panel--scores">
          <img src={topScoreIconUrl} className="bx-game-panel-icon" />
          <span>{playerScoreStats.max ? playerScoreStats.max.toFixed(0).padStart(2, '0') : '—'}</span>
        </div>
      </aside>
    </>
  )
}
