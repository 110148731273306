import type { FunctionComponent } from 'preact'
import clsx from 'clsx'

import logoImageUrl from '../../assets/images/icon.svg'

import './Icon.css'

/**
 * Icon/ Sign/ Logo Mark
 */
export const Icon: FunctionComponent<{ logomark?: boolean }> = ({ logomark = false }) => (
  <img className={clsx(`bx-icon`, { 'bx-icon--logomark': logomark })} src={logoImageUrl} />
)
