import type { FunctionComponent } from 'preact'
import { useEffect, useMemo } from 'preact/hooks'

import { useSystemStore } from '../../store/systemStore.ts'
import { useGameStore } from '../../store/gameStore.ts'
import { useAudioMixer } from '../../components/AudioMixer/AudioMixer.tsx'
import { GamePanels } from '../../components/GamePanels/GamePanels.tsx'
import { SECONDS } from '../../constants/DateTime.ts'
import { AUDIO } from '../../constants/Audio.ts'

import './SessionEnd.css'

type Result = {
  sequence: number
  value: number
}

/**
 * Note: Max 9 results fit on QHD display resolution
 */
export const SessionEnd: FunctionComponent = () => {
  const setPage = useSystemStore((state) => state.setPage)
  const endBoxingResult = useGameStore((state) => state.endBoxingResult)
  const setGameScore = useGameStore((state) => state.setGameScore)
  const playAudio = useAudioMixer()

  // Play background music
  useEffect(() => {
    const audioItems = AUDIO.PAGE_SESSION_END
    const randomAudio = audioItems[Math.floor(Math.random() * audioItems.length)]
    playAudio(randomAudio)
  }, [playAudio])

  // Reset game score because game has been reset partially so results data are available
  useEffect(() => setGameScore(null), [setGameScore])

  useEffect(() => {
    const timer = window.setTimeout(() => setPage('inactivity'), 5 * SECONDS)

    return () => window.clearTimeout(timer)
  }, [endBoxingResult, setPage])

  // Sort and limit
  const sortedResults: Result[] | undefined = useMemo(
    () =>
      endBoxingResult
        ?.map(({ value }, sequence) => ({
          sequence,
          value,
        }))
        .sort((resultA, resultB) => resultB.value - resultA.value),
    [endBoxingResult]
  )

  return (
    <section className="bx-page bx-page--session-end">
      {sortedResults && (
        <table className="bx-results-table">
          <tbody className="bx-results-table__body">
            {sortedResults.map(({ value, sequence }, index) => (
              <tr className="bx-results-table__row" key={index} style={{ '--bx-animation-delay': `${(index * 0.2).toFixed(2)}s` }}>
                <td className="bx-results-table__result-sequence">{sequence + 1}</td>
                <td className={`bx-results-table__result-value bx-results-table__result-value--index-${index}`}>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <GamePanels />
    </section>
  )
}
