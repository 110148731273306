import { devtools } from 'zustand/middleware'
import { create } from 'zustand'

import type { AccessToken } from './systemStore.ts'
import { getMonthDateRange } from '../utils/date.ts'
import { apiFetcher } from '../utils/fetcher.ts'

export type SessionToken = string

export type Credits = number

export type GameScore = number

export type PlayerScoresStats = {
  max: GameScore | null
}

export type EndBoxingResult = Array<{ value: number }>

export type Player = {
  email: string
  givenName: string
  familyName: string
  nickName: string
}

interface Game {
  sessionToken: SessionToken | null
  setSessionToken: (sessionToken: SessionToken | null) => void

  credits: Credits
  setCredits: (credits: Credits) => void

  gameScore: GameScore | null
  /** Set or reset game score and related state */
  setGameScore: (gameScore: GameScore | null) => void

  isGameScoreDisplayed: boolean
  setIsGameScoreDisplayed: (isGameScoreDisplayed: boolean) => void

  player: Player | null
  setPlayer: (player: Player | null) => void
  fetchPlayer: (id: number, accessToken: AccessToken | null) => Promise<void | Error>

  playerScoresStats: PlayerScoresStats
  fetchPlayerScoresStats: (playerId: number, accessToken: AccessToken | null) => Promise<void | Error>

  playerScoresIsNewMax: boolean

  endBoxingResult: EndBoxingResult | null
  setEndBoxingResult: (endBoxingResult: EndBoxingResult | null) => void

  /** Reset state after power-on */
  resetGame: () => void
}

export const useGameStore = create<Game>()(
  devtools(
    (set) => ({
      sessionToken: null,
      setSessionToken: (sessionToken) => set({ sessionToken }, undefined, { type: 'setSessionToken' }),

      credits: 0,
      setCredits: (credits) => set({ credits }, undefined, { type: 'setCredits' }),

      gameScore: null,
      // TODO: Reset stats on delayed sign-outs
      setGameScore: (gameScore) =>
        set(
          (state) => ({
            gameScore,
            // Note: Might remove for case when game/start event is missing
            isGameScoreDisplayed: false,
            playerScoresStats: recalcPlayerScoreStats(state.playerScoresStats, gameScore),
            // Detect new max for signed-in players
            playerScoresIsNewMax: state.player !== null && gameScore !== null && gameScore > (state.playerScoresStats.max ?? 0),
          }),
          undefined,
          { type: 'setGameScore' }
        ),

      isGameScoreDisplayed: false,
      setIsGameScoreDisplayed: (isGameScoreDisplayed) => set({ isGameScoreDisplayed }, undefined, { type: 'setIsGameScoreDisplayed' }),

      player: null,
      setPlayer: (player) => set({ player }, undefined, { type: 'setPlayer' }),
      fetchPlayer: (id, accessToken) => apiFetcher<Player>(`player/${id}`, accessToken).then((player) => set({ player }, undefined, { type: 'fetchPlayer' })),

      playerScoresStats: { max: null },
      fetchPlayerScoresStats: async (playerId, accessToken) => {
        const monthDateRange = getMonthDateRange(new Date())

        // Current month
        const path = `/game/scores/player/${playerId}`
        const urlSearchParams = new URLSearchParams()

        urlSearchParams.set('startDate', monthDateRange.startDate.toISOString())
        urlSearchParams.set('endDate', monthDateRange.endDate.toISOString())

        return apiFetcher<PlayerScoresStats>(`${path}?${urlSearchParams}`, accessToken).then((playerScoresStats) =>
          // Compute stats from current state for sign-ins during gameplay
          // This should not be required as endpoint responds with fresh data
          set(
            (state) => ({
              playerScoresStats: recalcPlayerScoreStats(state.playerScoresStats, playerScoresStats.max),
            }),
            undefined,
            { type: 'fetchPlayerScoresStats' }
          )
        )
      },

      playerScoresIsNewMax: false,

      endBoxingResult: null,
      setEndBoxingResult: (endBoxingResult) => set({ endBoxingResult }, undefined, { type: 'setEndBoxingResult' }),

      resetGame: () =>
        set(
          {
            sessionToken: null,
            credits: 0,
            gameScore: null,
            isGameScoreDisplayed: false,
            player: null,
            playerScoresStats: { max: null },
            playerScoresIsNewMax: false,
            endBoxingResult: null,
          },
          undefined,
          { type: 'resetGame' }
        ),
    }),
    { name: document.title, store: 'GameStore' }
  )
)

/**
 * Recalculate player score stats
 * Reset when game is over
 */
function recalcPlayerScoreStats(playerScoresStats: PlayerScoresStats, gameScore: GameScore | null): PlayerScoresStats {
  return {
    ...playerScoresStats,
    max: gameScore ? Math.max(gameScore, playerScoresStats.max ?? 0) : null,
  }
}
