import type { FunctionComponent, JSX } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { registerSW } from 'virtual:pwa-register'

import { useSystemStore } from '../../store/systemStore.ts'
import { HOURS, SECONDS } from '../../constants/DateTime.ts'
import { setModInterval } from '../../utils/timer.ts'

/**
 * Check for updates in interval
 * On update render fallback component instad of children
 * @note Using vanilla pwa-register package to call registerSW conditionally
 */
export const UpdateHandler: FunctionComponent<{
  startUrl: URL
  disableServiceWorker: boolean
  restartPending: JSX.Element
}> = ({ disableServiceWorker, restartPending, children }) => {
  const page = useSystemStore((state) => state.page)
  const [needRefresh, setNeedRefresh] = useState<boolean>(false)
  const [isRestarting, setIsRestarting] = useState<boolean>(false)

  const registrationRef = useRef<ServiceWorkerRegistration>()
  const updateServiceWorkerRef = useRef<ReturnType<typeof registerSW>>()

  /**
   * Register/ unregister service worker
   */
  useEffect(() => {
    if (!disableServiceWorker) {
      updateServiceWorkerRef.current = registerSW({
        immediate: true,
        onRegisteredSW: (_swScriptUrl, serviceWorkerRegistration) => (registrationRef.current = serviceWorkerRegistration),
        onNeedRefresh: () => setNeedRefresh(true),
        onRegisterError: () => (updateServiceWorkerRef.current = undefined),
      })
    } else {
      navigator.serviceWorker?.getRegistration().then((swRegistration) => swRegistration?.unregister())
    }
  }, [disableServiceWorker])

  /**
   * Periodic updates
   * @link https://vite-pwa-org.netlify.app/guide/periodic-sw-updates
   */
  useEffect(() => {
    const clearModInterval = setModInterval(async () => {
      if (registrationRef.current) {
        registrationRef.current.update().catch(() => {})
      } else if (await isUpdateAvailable()) {
        setNeedRefresh(true)
        clearModInterval()
      }
    }, 1 * HOURS)

    return () => clearModInterval()
  }, [])

  /**
   * Handle need to refresh
   */
  useEffect(() => {
    if (!needRefresh || (page !== 'inactivity' && location.pathname !== '/')) {
      return
    }

    let reloadTimeoutId: number | null = null

    setIsRestarting(true)

    if (updateServiceWorkerRef.current) {
      /**
       * The updateServiceWorker function reloads window in controlling event handler
       * @note deprecated reloadPage param is used to emphasize this
       * @see https://github.com/vite-pwa/vite-plugin-pwa/pull/394
       *
       * Since there is a check for current page, it's safe to reload into current location
       * Calling location.replace immediately after update leads to race condition and may lock browser (at least in local dev)
       */
      updateServiceWorkerRef.current(true).then(() => {
        /**
         * @note First update doesn't trigger page reload in vite-plugin-pwa, hence the fallback
         *       ...or when clientsClaim() is not run
         * @see https://github.com/vite-pwa/vite-plugin-pwa/issues/789
         */
        reloadTimeoutId = window.setTimeout(() => window.location.reload(), 5 * SECONDS)
      })
    } else {
      // Reload manually
      window.location.reload()
    }

    return () => reloadTimeoutId && window.clearTimeout(reloadTimeoutId)
  }, [needRefresh, page])

  // TODO: Use modal
  return <>{isRestarting ? restartPending : children}</>
}

/**
 * Check for new release
 * @note Request will fail after pull request is merged - Pull Request Preview will be disposed
 */
async function isUpdateAvailable(): Promise<boolean> {
  const appVersion = import.meta.env.VITE_APP_VERSION
  const appVersionPathname = import.meta.env.VITE_APP_VERSION_PATHNAME

  let response: Response

  try {
    response = await fetch(new URL(appVersionPathname, window.location.href), {
      cache: 'no-store',
    })
  } catch {
    return false
  }

  if (!response.ok) {
    return false
  }

  const releasedVersion = await response.text()

  return releasedVersion !== appVersion
}
