import type { FunctionComponent, JSX } from 'preact'
import { useState } from 'preact/hooks'

import { useSystemStore } from '../../store/systemStore.ts'
import { apiFetcher } from '../../utils/fetcher.ts'
import { ConnectError } from '../../utils/Error/index.ts'

import './Sign-In.css'

/**
 * Sign-in Page
 */
export const SignIn: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const serialNumber = useSystemStore((state) => state.serialNumber)
  const setAccessToken = useSystemStore((state) => state.setAccessToken)
  const setPage = useSystemStore((state) => state.setPage)

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const handleSubmit: JSX.GenericEventHandler<HTMLFormElement> = async (submitEvent) => {
    submitEvent.preventDefault()

    if (submitEvent.target instanceof HTMLFormElement) {
      const formData = new FormData(submitEvent.target)

      const password = formData.get('password')

      if (!password) {
        return
      }

      setErrorMessage(null)
      setIsLoading(true)

      let payload: { accessToken: string }

      // Basic auth credentials
      const credentials = btoa(`operator:${password}`)

      try {
        payload = await apiFetcher(`/terminal/${serialNumber}/sign-in`, null, {
          method: 'POST',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        })
      } catch (transferError) {
        return setErrorMessage(transferError instanceof ConnectError ? 'Brak połączenia z API' : 'Błąd logowania')
      } finally {
        setIsLoading(false)
      }

      setAccessToken(payload.accessToken)

      // Redirect
      setPage('inactivity')
    }
  }

  // Form elements tabIndex
  let tabIndex = 0

  return (
    <section className="bx-page bx-page-sign-in">
      <h2 className="bx-page-title">{'Logowanie'}</h2>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <p>
          {'Numer seryjny'}: <code>{serialNumber}</code>
        </p>

        <fieldset className="bx-form-fieldset" disabled={isLoading}>
          <div className="bx-form-grid">
            <label className="bx-form-label" htmlFor="password">
              {'Hasło'}
            </label>
            <input
              id="password"
              className="bx-form-input bx-form-input--password"
              name="password"
              type="password"
              autoComplete="one-time-code"
              required
              placeholder="123"
              tabIndex={++tabIndex}
            />
          </div>

          <button className="bx-form-button" type="submit" tabIndex={++tabIndex}>
            {'Wyślij'}
          </button>
        </fieldset>

        <div className="bx-form-error" hidden={!errorMessage}>
          {'Błąd'}: {errorMessage}
        </div>
      </form>
    </section>
  )
}
