const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  dateStyle: 'short',
  timeStyle: 'medium',
}

/**
 * Format message prefix to include full date
 * @note DevTools have an option to prefix logs with timestamps (in hh:mm:ss.sss) which is not so helpful for long-running scripts
 *
 * @example With info
 * ```ts
 * console.info(...formatPrefix('EventStream', 'slateblue'), error)
 * ```
 * @example With group
 * ```ts
 * console.groupCollapsed(...formatPrefix('App Error', 'red'))
 * console.dir(error)
 * console.groupEnd()
 * ```
 */
export function formatPrefix(label: string, color: string): string[] {
  const now = new Date()

  // Use ISO8601 like format in local time zone
  const timestamp = now.toLocaleString(['sv-SE'], dateTimeFormatOptions)

  /*
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset())
  const timestamp = now.toISOString().replace(/^(.*)T(.*)Z$/, '$1 $2')
  */

  return ['%c[%s] %c%s', `color: ${color}; font-weight: bold`, label, 'color: #8f8f8f', timestamp]
}
