import type { ComponentType, FunctionComponent } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'

import type { ItemContentProps } from '../../Inactivity.tsx'
import type { GlobalScoresStat, GlobalScoresStats } from './types'
import { useSystemStore } from '../../../../store/systemStore.ts'
import { apiFetcher } from '../../../../utils/fetcher.ts'

import { MINUTES } from '../../../../constants/DateTime.ts'
import { getMonthDateRange } from '../../../../utils/date.ts'

import './GameScores.css'

type ProcessedGlobalScoresStat = GlobalScoresStat & { position: number }
type ProcessedGlobalScoresStats = ProcessedGlobalScoresStat[] | null

type GameScoresProps = {
  filters: {
    sex?: 'M' | 'F'
    ageCategory?: 'Youth' | 'Junior' | 'Open'
    weightCategory?: string
  }
  subCaption?: string
  groupByTerminalCity?: boolean
}

type ComponentParams = ItemContentProps & GameScoresProps

/**
 * Global scores content (max | avg)
 * Use instead of deprecated Global Scores component
 */
export const GlobalScores: FunctionComponent<ComponentParams> = ({ isCurrent, filters, subCaption = undefined, groupByTerminalCity = false }) => {
  const accessToken = useSystemStore((state) => state.accessToken)

  const [globalScoresStats, setGlobalScoresStats] = useState<GlobalScoresStats | null>(null)
  const [fetchDateTs, setFetchDateTs] = useState<number | null>(null)

  // Sort by position
  const processedGlobalScoreStats: ProcessedGlobalScoresStats = useMemo(
    () =>
      globalScoresStats?.data
        // Clone
        .slice()
        // Sort by value
        .sort((statA, statB) => statB.value - statA.value || statA.player.nickName.localeCompare(statB.player.nickName))
        // Add position
        .map((stat, index) => ({ ...stat, position: index })) ?? null,
    [globalScoresStats]
  )

  // Fetch
  useEffect(() => {
    if (!accessToken || !isCurrent) {
      return
    }

    const now = new Date()

    // Cache not stale
    if (fetchDateTs && fetchDateTs + 5 * MINUTES > now.getTime()) {
      return
    }

    const monthDateRange = getMonthDateRange(now)

    const path = '/game/scores/aggregate'
    const urlSearchParams = new URLSearchParams()

    // Current month
    urlSearchParams.set('startDate', monthDateRange.startDate.toISOString())
    urlSearchParams.set('endDate', monthDateRange.endDate.toISOString())

    // Aggregate function
    urlSearchParams.set('fn', 'max')

    // Group by
    if (groupByTerminalCity) {
      urlSearchParams.set('groupBy', 'terminal.city.name')
    }

    // Add filters
    for (const [key, value] of Object.entries(filters)) {
      urlSearchParams.set(key, value)
    }

    // Pagination
    urlSearchParams.set('offset', '0')
    urlSearchParams.set('limit', '15')

    const abortController = new AbortController()

    apiFetcher<GlobalScoresStats>(`${path}?${urlSearchParams}`, accessToken, { signal: abortController.signal })
      .then((globalScoresState) => {
        setGlobalScoresStats(globalScoresState)
        setFetchDateTs(Date.now())
      })
      .catch(() => {})

    return () => abortController.abort('unmount')
  }, [accessToken, filters, groupByTerminalCity, isCurrent, fetchDateTs])

  return (
    <table className="bx-global-scores-table">
      <caption className="bx-global-scores-table__caption">
        {'Ranking miesiąca'}
        {subCaption && <small className="bx-global-scores-table__subcaption">{subCaption}</small>}
      </caption>
      {/** Show head when data is available */}
      {processedGlobalScoreStats && processedGlobalScoreStats.length > 0 && (
        <thead>
          <tr className="bx-global-scores-table__row">
            <th>
              <span>{'Miejsce'}</span>
            </th>
            <th>
              <span>{'Ksywka'}</span>
            </th>
            <th>
              <span>{groupByTerminalCity ? 'Miasto' : 'Max'}</span>
            </th>
          </tr>
        </thead>
      )}
      {processedGlobalScoreStats ? (
        // Data
        <tbody className="bx-global-scores-table__body">
          {processedGlobalScoreStats.map((scoreStat, index, scoreStats) => (
            <tr key={index} className="bx-global-scores-table__row">
              <td className="bx-global-scores-table__position">
                {index && scoreStats.at(index - 1)?.position === scoreStat.position ? null : scoreStat.position + 1}
              </td>
              <td className="bx-global-scores-table__nickname">{scoreStat.player.nickName}</td>
              <td className="bx-global-scores-table__data">{groupByTerminalCity ? scoreStat.groupByValue : scoreStat.value}</td>
            </tr>
          ))}
        </tbody>
      ) : (
        // Loading indicator
        <tbody className="bx-global-scores-table__message-container">
          <tr>
            <td>
              <p>{'Wczytywanie danych…'}</p>
            </td>
          </tr>
        </tbody>
      )}
      {/** Low item count indicator */}
      {processedGlobalScoreStats && processedGlobalScoreStats.length < 10 && (
        <tfoot className="bx-global-scores-table__message-foot bx-global-scores-table__message-container">
          <tr>
            <td colSpan={3}>
              <p className="bx-text-uppercase">{'nowy miesiąc = nowy ranking'}</p>
              <p>{'zagraj, żeby zająć miejsce w rankingu'}</p>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  )
}

/**
 * Create GloabScores component with given props
 */
export function withGameScoresProps<P extends ItemContentProps>(
  WrappedComponent: ComponentType<ComponentParams>,
  gameScoresProps: GameScoresProps
): ComponentType<P> {
  const Wrapped: ComponentType<P> = (props) => <GlobalScores {...props} {...gameScoresProps} />

  Wrapped.displayName = `withGameScoresProps(${
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component'
  })`

  return Wrapped
}
