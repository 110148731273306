import TransferError from './TransferError.ts'

/**
 * Error payload
 *
 * @link https://www.fastify.io/docs/latest/Reference/Reply/#errors
 *
 * @example 404 Not Found
 * ```json
 * {
 *   "error": "Not Found",
 *   "message": "Route GET:/404 not found",
 *   "statusCode": 404
 * }
 * ```
 */
type ResponseJson = {
  /** The HTTP error message */
  error: string
  /** The Fastify/ App error code */
  code?: string
  /** The user error message */
  message: string
  /** The HTTP status code */
  statusCode: number
}

/**
 * API Reponse error
 */
export default class ApiError extends TransferError {
  public name = 'ApiError'

  constructor(
    request: Request,
    public response: Response,
    public responseJson?: ResponseJson
  ) {
    super(request, 'An error occured while fetching the data')

    Object.setPrototypeOf(this, ApiError.prototype)
  }
}
