import type { FunctionComponent } from 'preact'
import { useEffect, useRef } from 'preact/hooks'

import backgroundVideo from './videos/background-h.264.mp4'
import backgroundPoster from './videos/background-h.264.jpg'

import './BackgroundVideo.css'

/**
 * @todo Use video source from video preloader
 *       to prevent issues when it's served as 206 Partal Content and breaks service worker runtime cache
 */
export const BackgroundVideo: FunctionComponent<{ pause?: boolean }> = ({ pause = false }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const playPromise = useRef<Promise<void> | null>(null)

  // Toggle play/ pause state
  useEffect(() => {
    if (!videoRef.current) {
      return
    }

    if (pause && playPromise.current) {
      return void playPromise.current.then(() => videoRef.current?.pause())
    }

    // Workaround exception when moving mouse on page load
    if (videoRef.current.paused) {
      /**
       * @throws {DOMException} - 'The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22'
       * @throws {DOMException} - AbortError: 'The play() request was interrupted because video-only background media was paused to save power'
       */
      playPromise.current = videoRef.current.play().catch(() => {})
    }
  }, [pause])

  return (
    <video
      ref={videoRef}
      className="bx-background-video"
      autoPlay={true}
      controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
      disablePictureInPicture
      loop={true}
      muted={true}
      poster={backgroundPoster}
      src={backgroundVideo}
      crossorigin="anonymous"
      onLoadStart={(event) => event.target instanceof HTMLVideoElement && (event.target.playbackRate = 0.5)}
    />
  )
}
