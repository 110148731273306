import type { FunctionComponent } from 'preact'
import { useEffect } from 'preact/hooks'

import { useSystemStore } from '../../store/systemStore.ts'
import { SECONDS } from '../../constants/DateTime.ts'

import './InfoNoCredits.css'

/**
 * No Credits page
 */
export const InfoNoCredits: FunctionComponent = () => {
  const setPage = useSystemStore((state) => state.setPage)

  useEffect(() => {
    const exitTimeoutId = window.setTimeout(() => setPage('inactivity'), 5 * SECONDS)

    return () => window.clearTimeout(exitTimeoutId)
  })

  return (
    <section className="bx-page bx-page--info-no-credits">
      <h2 className="bx-page-title">{'Brak kredytów'}</h2>
      <p>{'Załaduj kredyty, aby rozpocząć grę'}</p>
    </section>
  )
}
