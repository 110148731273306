import type { FunctionComponent } from 'preact'
import { useEffect, useCallback, useRef } from 'preact/hooks'
import { useShallow } from 'zustand/react/shallow'
import clsx from 'clsx'

import { useCursorShow } from './hooks/useCursorShow.ts'
import { useWakeLock } from './hooks/useWakeLock.ts'
import { useIsPageStatePassive } from './hooks/useIsPageStatePassive.ts'
import { UpdateHandler } from './components/UpdateHandler/UpdateHandler.tsx'
import { AppInfoPanel } from './components/AppInfoPanel/AppInfoPanel.tsx'
import { AudioMixerProvider, useAudioMixer } from './components/AudioMixer/AudioMixer.tsx'
import { VideoProvider } from './components/VideoProvider/VideoProvider.tsx'
import { BackgroundVideo } from './components/BackgroundVideo/BackgroundVideo.tsx'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary.tsx'
import { SystemStateProvider } from './components/SystemStateProvider/SystemStateProvider.js'
import { useGameStore } from './store/gameStore.ts'
import { useSystemStore } from './store/systemStore.ts'
import { useEventStream } from './hooks/useEventStream.ts'
import type { EventSourceMessageTypes } from './interfaces/EventSourceMessage.d.ts'

import { SECONDS } from './constants/DateTime.ts'
import { AUDIO } from './constants/Audio.ts'

import { Init } from './pages/Init/Init.tsx'
import { Inactivity } from './pages/Inactivity/Inactivity.tsx'
import { SessionStart } from './pages/SessionStart/SessionStart.tsx'
import { GameStart } from './pages/GameStart/GameStart.tsx'
import { GameReady } from './pages/GameReady/GameReady.tsx'
import { GameScores } from './pages/GameScores/GameScores.tsx'
import { SessionEnd } from './pages/SessionEnd/SessionEnd.tsx'
import { InfoNoCredits } from './pages/InfoNoCredits/InfoNoCredits.tsx'
import { Message } from './pages/Message/Message.tsx'
import { Configuration } from './pages/Configuration/Configuration.tsx'
import { SignIn } from './pages/Sign-In/Sign-In.tsx'

import './App.css'

const App: FunctionComponent = () => {
  const playAudio = useAudioMixer()

  const [serialNumber, accessToken, page, setPage, isGamePage] = useSystemStore(
    useShallow((state) => [state.serialNumber, state.accessToken, state.page, state.setPage, state.isGamePage])
  )

  const [setSessionToken, setPlayer, fetchPlayer, fetchPlayerScoresStats, setCredits, setGameScore, setIsGameScoreDisplayed, setEndBoxingResult, resetGame] =
    useGameStore(
      useShallow((state) => [
        state.setSessionToken,
        state.setPlayer,
        state.fetchPlayer,
        state.fetchPlayerScoresStats,
        state.setCredits,
        state.setGameScore,
        state.setIsGameScoreDisplayed,
        state.setEndBoxingResult,
        state.resetGame,
      ])
    )

  const isMachinePairedRef = useRef<boolean | null>(null)
  const sessionEndTimeoutIdRef = useRef<number | null>(null)

  const eventSourceReadyState = useEventStream(
    serialNumber,
    accessToken,
    useCallback(
      (eventSourceMessage) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let data: any = undefined

        if (eventSourceMessage.data) {
          try {
            /** @throws {SyntaxError} */
            data = JSON.parse(eventSourceMessage.data)
          } catch (syntaxError) {
            // Noop
          }
        }

        switch (eventSourceMessage.event) {
          // Initial retry info
          case '':
            break

          // No-ops
          case 'system/shutdown':
          case 'stream/start':
          case 'stream/ping':
            break

          // Credits top-up event
          case 'game/credits':
            setCredits(data satisfies EventSourceMessageTypes['game/credits'])
            playAudio(AUDIO.EVENT_GAME_CREDITS)

            break

          // Session start event (no sound, there is one for game/credits)
          case 'session/start':
            setSessionToken(data.token satisfies EventSourceMessageTypes['session/start'])

            setPlayer(null)
            setGameScore(null)
            setEndBoxingResult(null)

            setPage('session/start')

            break

          // Player sign-in event
          case 'player/sign-in':
            playAudio(AUDIO.EVENT_PLAYER_SIGN_IN)

            try {
              fetchPlayer(data.playerId satisfies EventSourceMessageTypes['player/sign-in']['playerId'], accessToken).catch(() => {})
              fetchPlayerScoresStats(data.playerId satisfies EventSourceMessageTypes['player/sign-in']['playerId'], accessToken).catch(() => {})
            } catch {
              // Continue regardless of error
            }

            break

          // Player sign-out event (no sound, there is one for game/scores)
          case 'player/sign-out':
            setPlayer(null)

            break

          // Game Start event
          case 'game/start':
            // Reset score displayed
            // Note: Also reset on LED screen ~2min after game/scores event
            setIsGameScoreDisplayed(false)

            playAudio(AUDIO.EVENT_GAME_START)

            setPage('game/start')

            break

          // Game Ready event
          case 'game/ready':
            playAudio(AUDIO.EVENT_GAME_READY)

            setPage('game/ready')

            break

          // Game Scores event
          case 'game/scores':
            setGameScore(data.value satisfies EventSourceMessageTypes['game/scores']['value'])
            setCredits(data.credits satisfies EventSourceMessageTypes['game/scores']['credits'])

            playAudio(AUDIO.EVENT_GAME_SCORES)

            setPage('game/scores')

            break

          // Game Scores Displayed event
          case 'game/scores-displayed':
            setIsGameScoreDisplayed(true)

            break

          // Session end event
          case 'session/end':
            setEndBoxingResult(data satisfies EventSourceMessageTypes['session/end'])

            if (data.length) {
              // Show last score before summary
              sessionEndTimeoutIdRef.current = window.setTimeout(() => setPage('session/end'), 10 * SECONDS)
            } else {
              // No results (session closed due to user inactivity)
              setPage('inactivity')
            }

            break

          // Machine paired event
          case 'machine/paired':
            if (isMachinePairedRef.current !== data) {
              setPage(data ? 'inactivity' : 'configuration')
            }

            isMachinePairedRef.current = data satisfies EventSourceMessageTypes['machine/paired']

            break

          // Info: No credits event
          case 'info/no-credits':
            setPage('info/no-credits')

            break

          // Reset current game event
          case 'game/reset': {
            resetGame()

            // Note: Should reset on a Sign-in which should be a modal

            // Reset route when in game
            if (isGamePage()) {
              setPage('inactivity')
            }

            break
          }

          // Unknown event type
          default:
            break
        }
      },
      [
        accessToken,
        playAudio,
        setPage,
        setSessionToken,
        setPlayer,
        fetchPlayer,
        fetchPlayerScoresStats,
        setCredits,
        setGameScore,
        setIsGameScoreDisplayed,
        setEndBoxingResult,
        resetGame,
        isGamePage,
      ]
    )
  )

  useEffect(() => {
    // Cancel timer
    if (sessionEndTimeoutIdRef.current) {
      window.clearTimeout(sessionEndTimeoutIdRef.current)
      sessionEndTimeoutIdRef.current = null
    }
  }, [page])

  // Fatal error
  if (!serialNumber) {
    return <Message text={'Brakujący lub błędny parametr\n "serial-number" w adresie URL'} error />
  }

  // Connection states
  // TODO: Use modals
  switch (eventSourceReadyState) {
    case null:
      return <Init />

    case EventSource.CONNECTING:
      return <Message text="Trwa łączenie z serwerem API…" />

    case EventSource.CLOSED:
      return <Message text="Brak połączenia z serwerem API" error />

    case EventSource.OPEN:
    default:
      break
  }

  // prettier-ignore
  switch (page) {
    case 'configuration': return <Configuration />
    case 'sign-in': return <SignIn />
    case 'inactivity': return <Inactivity />
    case 'session/start': return <SessionStart />
    case 'game/start': return <GameStart />
    case 'game/ready': return <GameReady />
    case 'game/scores': return <GameScores />
    case 'session/end': return <SessionEnd />
    case 'info/no-credits': return <InfoNoCredits />
    case 'init':
    default: return <Init />
  }
}

/**
 * Wrap app component by providers
 */
const AppWrapper: FunctionComponent<{ startUrl: URL; env: ImportMetaEnv }> = ({ startUrl, env }) => {
  const [showCursor, setElementRef] = useCursorShow()
  const isPageStatePassive = useIsPageStatePassive()

  useWakeLock()

  return (
    <main ref={setElementRef} className={clsx('bx-app', { 'bx-app--show-cursor': showCursor })}>
      <ErrorBoundary>
        <SystemStateProvider startUrl={startUrl} fallback={<Message text="Wczytywanie stanu…" />}>
          <UpdateHandler startUrl={startUrl} disableServiceWorker={env.VITE_APP_DISABLE_SW === 'true'} restartPending={<Message text={'Aktualizacja…'} />}>
            <AudioMixerProvider fallback={<Message text="Ładowanie dźwięków…" />}>
              <VideoProvider fallback={<Message text="Ładowanie animacji…" />}>
                <App />
              </VideoProvider>
            </AudioMixerProvider>
          </UpdateHandler>
          <AppInfoPanel open={showCursor} />
        </SystemStateProvider>
      </ErrorBoundary>
      <BackgroundVideo pause={isPageStatePassive || showCursor} />
    </main>
  )
}

export { AppWrapper as App }
