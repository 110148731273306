import { ApiError, ConnectError } from './Error/index.ts'

/**
 * API Fetcher
 * @throws {ConnectError|ApiError|SyntaxError}
 * @note Easiest way to not use Base API Url as env var is with system store value; SWR might help
 */
export async function apiFetcher<ResponsePayload>(relativeUrl: string, accessToken: string | null, init: RequestInit = {}): Promise<ResponsePayload> {
  const url = new URL(relativeUrl, import.meta.env.VITE_API_URL)
  const headers = new Headers(init?.headers)

  // Set content type only when body is present
  if (init.body) {
    headers.set('Content-Type', 'application/json')
  }

  // Note: Will be overwritten by init.headers
  if (accessToken && !headers.has('authorization')) {
    headers.set('Authorization', `Bearer ${accessToken}`)
  }

  const request = new Request(url, {
    method: 'GET',
    headers,
    credentials: 'include',
    ...init,
  })

  let response: Response

  try {
    response = await globalThis.fetch(request)
  } catch (typeError) {
    throw new ConnectError(request, { cause: typeError as TypeError })
  }

  // No content
  if (response.status === 204) {
    return null as ResponsePayload
  }

  // Invalid content type
  if (!response.headers.get('content-type')?.startsWith('application/json')) {
    throw new ApiError(request, response)
  }

  /** @throws {SyntaxError} - Note: Should check for proper content type */
  const responseJson = await response.json()

  // Invalid status code
  if (!response.ok) {
    throw new ApiError(request, response, responseJson)
  }

  return responseJson
}
