import TransferError from './TransferError.ts'

/**
 * Wrapper for TypeError: Failed to fetch
 */
export default class ConnectError extends TransferError {
  public name = 'ConnectError'

  constructor(request: Request, options?: ErrorOptions) {
    super(request, 'No connection', options)

    Object.setPrototypeOf(this, ConnectError.prototype)
  }
}
